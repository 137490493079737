@use 'styles/global' as global;
@use 'styles/errorPage' as error;
@use 'styles/table' as table;
@use 'styles/datatable' as datatable;
@use 'styles/leaflet' as leaflet;
@use 'app/index' as appIndex;
@use 'styles/graph' as graph;
@use 'styles/mixins' as mixins;
@import '../node_modules/c3/c3.css';

ui-svg-icon[icon='impressions'],
ui-svg-icon[icon='click'] {
    font-size: 2rem;
}
