@use './mixins' as mixins;

//Progress bar animations
@-webkit-keyframes query {
    0% {
        opacity: 1;
        transform: translateX(35%) scale(0.3, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) scale(0, 1);
    }
}

@-moz-keyframes query {
    0% {
        opacity: 1;
        transform: translateX(35%) scale(0.3, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) scale(0, 1);
    }
}

@-ms-keyframes query {
    0% {
        opacity: 1;
        transform: translateX(35%) scale(0.3, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) scale(0, 1);
    }
}

@-o-keyframes query {
    0% {
        opacity: 1;
        transform: translateX(35%) scale(0.3, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) scale(0, 1);
    }
}

@keyframes query {
    0% {
        opacity: 1;
        transform: translateX(35%) scale(0.3, 1);
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) scale(0, 1);
    }
}

.ngx-datatable {
    $cellVerticalPadding: 0.5rem;
    $cellHeight: 3rem;
    $cellInnerHeight: $cellHeight - ($cellVerticalPadding * 2);
    $checkboxSize: 1.6rem;
    $checkboxRadius: 0.2rem;
    $checkboxBorderThickness: 0.1rem;
    $footerItemSize: 3rem;
    $footerHeight: 5rem;
    background: var(--ui-color-background-second);

    &.striped {
        .datatable-row-odd .datatable-row-group {
            background-color: rgba(0, 0, 0, 0.015);
        }
    }

    .empty-row {
        line-height: 5rem;
        text-align: center;
        font-style: italic;
        color: var(--ui-color-grey-61);
        padding: $cellVerticalPadding 1.2rem;
        vertical-align: top;
        border-top: 0;
    }

    &[ng-reflect-scrollbar-h='false'] {
        .datatable-scroll,
        .datatable-body-row,
        .datatable-row-group {
            max-width: 100%;
        }

        .datatable-scroll {
            width: 100% !important;
        }
    }

    &.single-selection,
    &.multi-selection,
    &.multi-click-selection {
        .datatable-body-row {
            &.active,
            &.active .datatable-row-group {
                background-color: #304ffe;
            }

            &.active:hover,
            &.active:hover .datatable-row-group {
                background-color: #193ae4;
            }

            &.active:focus,
            &.active:focus .datatable-row-group {
                background-color: #2041ef;
            }
        }
    }

    &:not(.cell-selection) {
        .datatable-body-row {
            &:hover,
            &:hover .datatable-row-group {
                background-color: rgba(0, 0, 0, 0.028);
                transition-property: background-color;
                transition-duration: 0.3s;
                transition-timing-function: linear;
            }

            &:focus,
            &:focus .datatable-row-group {
                background-color: var(--ui-color-grey-86);
            }
        }
    }

    &.cell-selection {
        .datatable-body-cell {
            &:hover,
            &:hover .datatable-row-group {
                background-color: rgba(0, 0, 0, 0.4);
                transition-property: background-color;
                transition-duration: 0.3s;
                transition-timing-function: linear;
            }

            &:focus,
            &:focus .datatable-row-group {
                background-color: rgba(0, 0, 0, 0.4);
            }

            &.active,
            &.active .datatable-row-group {
                background-color: #304ffe;
            }

            &.active:hover,
            &.active:hover .datatable-row-group {
                background-color: #193ae4;
            }

            &.active:focus,
            &.active:focus .datatable-row-group {
                background-color: #2041ef;
            }
        }
    }

    //Shared Styles
    .loading-row {
        text-align: left;
        padding: $cellVerticalPadding 1.2rem;
        vertical-align: top;
        border-top: 0;
    }

    //Global Row Styles
    .datatable-header,
    .datatable-body {
        datatable-selection {
            min-width: 100%;
        }

        .datatable-row-left {
            background-position: 100% 0;
            background-repeat: repeat-y;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==');
        }

        .datatable-row-right {
            background-position: 0 0;
            background-color: var(--ui-color-surface);
            background-repeat: repeat-y;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==');
        }
    }

    .datatable-header {
        border-top: 1px solid transparent;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        height: $cellHeight + 0.2rem;
        background: var(--ui-color-surface-second);

        .datatable-header-inner {
            height: 100%;
        }

        datatable-header-cell {
            margin-top: -1px;
            text-align: left;
            padding: 0 1.2rem;
            font-weight: 400;
            vertical-align: bottom;
            height: 100%;
            position: relative;

            &.text-right {
                text-align: right;
            }

            > div {
                height: 100%;
                padding: 0;
                margin: 0;
            }

            .datatable-header-cell-wrapper {
                position: relative;
                min-width: 1px;
                height: 100%;
            }

            .sort-btn {
                transition:
                    transform 0.2s ease,
                    opacity 0.2s ease;
                opacity: 0;
                position: absolute;
                right: 7px;
                top: 28px;

                &.datatable-icon-down,
                &.datatable-icon-up {
                    opacity: 1;
                    font:
                        normal normal normal 10px / 1 'BannerflowMaterial',
                        sans-serif;
                    width: 15px;
                    height: 6px;
                    background-image: url("data:image/svg+xml,%3Csvgxmlns='http://www.w3.org/2000/svg'version='1.1'width='15'height='15'viewBox='00448448'%3E%3Ctitle/%3E%3Cgid='icomoon-ignore'%3E%3C/g%3E%3Cpathfill='%23000'd='M340.041181.37c5.871-6.60515.984-7.222.589-1.329s7.215.9841.32922.589l-104.083117.093c-17.61219.814-47.95221.598-67.7653.986-1.406-1.249-2.737-2.58-3.986-3.986l-104.083-117.093c-5.871-6.605-5.276-16.7181.329-22.589s16.718-5.27622.5891.329l104.083117.093c0.4170.4690.860.9121.3291.3296.6055.87116.7185.27622.589-1.329l104.083-117.093z'/%3E%3C/svg%3E");
                    background-position: center -6px;
                }

                &.datatable-icon-up {
                    transform: rotate(180deg);
                }
            }

            &.longpress {
                .draggable::after {
                    transition:
                        transform 400ms ease,
                        opacity 400ms ease;
                    opacity: 0.5;
                    transform: scale(1);
                }
            }

            .draggable::after {
                content: ' ';
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -30px 0 0 -30px;
                height: 60px;
                width: 60px;
                background: var(--ui-color-grey-93);
                border-radius: 100%;
                opacity: 1;
                filter: none;
                transform: scale(0);
                z-index: 9999;
                pointer-events: none;
            }

            &.dragging {
                .resize-handle {
                    border-right: none;
                }
            }
        }
    }
    // Body Styles
    .datatable-body {
        .datatable-row-detail {
            background: var(--ui-color-grey-96);
            padding: 10px;
        }

        .datatable-body-row {
            .datatable-body-cell {
                position: relative;
                text-align: left;

                padding: 0rem 1.2rem;
                margin-top: -0.2rem;
                vertical-align: top;
                text-overflow: initial !important;
                overflow: visible !important;

                &.text-right {
                    text-align: right;
                }

                .datatable-body-cell-label {
                    vertical-align: middle;
                    display: inline-block;
                    user-select: initial;
                    max-width: 100%;

                    > span {
                        display: inline-block;
                        max-width: 100%;
                        vertical-align: top;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &.live-bannerset {
                            max-width: 95%;
                        }

                        &.live-folder {
                            max-width: 89%;
                        }
                    }
                }

                &:before {
                    content: '';
                    display: inline-block;
                    height: 100%;

                    vertical-align: middle;
                    pointer-events: none;
                }
            }
        }

        .progress-linear {
            display: block;
            width: 100%;
            height: 5px;
            padding: 0;
            margin: 0;
            position: absolute;

            .container {
                display: block;
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 5px;
                transform: translate(0, 0) scale(1, 1);
                background-color: rgb(170, 209, 249);

                .bar {
                    animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
                    transition: transform 0.2s linear;
                    background-color: rgb(16, 108, 200);
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    height: 5px;
                }
            }
        }
    }

    //Footer Styles
    .datatable-footer {
        border-top: none;
        font-size: var(--ui-font-size);
        color: rgba(0, 0, 0, 0.54);

        .page-count {
            line-height: $footerHeight;
            height: $footerHeight;
            padding: 0 1.2rem;
        }

        .datatable-pager {
            margin: 0 $footerHeight - calc($footerItemSize / 2);

            li {
                vertical-align: middle;
                border: 1px solid var(--ui-color-grey-89);
                border-radius: 2px;
                height: $footerItemSize;
                opacity: 1;
                transition: opacity 0.2s ease;
                margin: 0;

                &.disabled {
                    opacity: 0.4;
                }

                &.active a {
                    background-color: rgba(0, 0, 0, 0.05);
                    font-weight: bold;
                }

                &.pages a {
                    position: relative;
                    top: 0px;
                }
            }

            a {
                min-width: $footerItemSize - 0.2rem;
                line-height: $footerItemSize - 0.2rem;
                text-align: center;
                color: rgba(0, 0, 0, 0.54);
                text-decoration: none;
                vertical-align: bottom;
                position: relative;
                top: 2px;
            }

            .datatable-icon-left,
            .datatable-icon-skip,
            .datatable-icon-right,
            .datatable-icon-prev {
                opacity: 1;
                font:
                    normal normal normal 14px / 1 'BannerflowMaterial',
                    sans-serif;
                font-size: inherit;
                line-height: inherit;
            }

            .datatable-icon-left:before {
                transform: rotate(90deg);
            }

            .datatable-icon-right:before {
                transform: rotate(-90deg);
            }
        }
    }

    //Checkboxes
    .datatable-checkbox {
        $checkboxPadding: $cellInnerHeight - calc($checkboxSize / 2);
        position: relative;
        margin: 0;
        cursor: pointer;
        vertical-align: middle;
        display: inline-block;
        box-sizing: border-box;
        width: $checkboxSize;
        height: $checkboxSize + ($checkboxPadding * 2);
        padding: $checkboxPadding 0;

        input[type='checkbox'] {
            position: relative;
            margin: 0 $checkboxSize 0 0;
            display: inline-block;
            width: $checkboxSize;
            height: $checkboxSize;
            cursor: pointer;
            outline: none;
            font:
                normal normal normal 14px / 1 'BannerflowMaterial',
                sans-serif;
            text-align: center;
            overflow: hidden;
            z-index: 10;

            &:before {
                content: '\f03e';
                color: var(--ui-color-white);
                line-height: $checkboxSize - (2 * $checkboxBorderThickness);
                text-align: center;
                position: absolute;
                pointer-events: none;
                left: 0;
                top: calc($checkboxSize / 2);
                z-index: 1;
                opacity: 0;
                border: $checkboxBorderThickness solid transparent;
                transition: all 0.2s ease;
            }

            &:checked {
                &:before {
                    transform: scale(1);
                    top: 0;
                    opacity: 1;
                }

                &:after {
                    background: var(--ui-color-primary);
                    border-color: var(--ui-color-primary);
                }
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: $checkboxSize;
                height: $checkboxSize;
                background: var(--ui-color-white);
                cursor: pointer;
                border-radius: $checkboxRadius;
                border: $checkboxBorderThickness solid var(--ui-color-border);
                transition: all 0.2s ease;
            }
        }
    }
}

.ngx-datatable .datatable-header .datatable-header-cell span.resize-handle {
    cursor: pointer;
}

.ngx-datatable .datatable-footer .datatable-footer-inner {
    justify-content: space-between;
    padding: 0 9em;
}
