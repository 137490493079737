.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    thead {
        tr {
            th {
                font-size: var(--ui-font-size);
                text-align: left;
                padding: 0 2rem;
                font-weight: normal;
                color: rgba(0, 0, 0, 0.4);
            }
        }
    }

    tbody {
        tr {
            background: var(--ui-color-grey-84);
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            min-height: 45px;
            margin-bottom: 10px;
            &.table--invalid {
                td {
                    background: var(--ui-color-surface);
                    border-color: var(--ui-color-alert);
                }
            }

            td {
                padding: 0 2rem;
                border: 1px solid rgba(151, 151, 151, 0.3);
                border-left-width: 0;
                border-right-width: 0;

                height: 50px;
                white-space: nowrap;
                position: relative;
                background: white;
                &:first-child {
                    border-radius: 2px 0 0 2px;
                    border-left-width: 1px;
                }
                &:last-child {
                    border-radius: 0 2px 2px 0;
                    border-right-width: 1px;
                }
                & > ui-flag .ui-flag {
                    vertical-align: -3px;
                    margin-right: 5px;
                }
                &.table__subText {
                    color: var(--ui-color-grey-61);
                }
                &.table__action {
                    width: 45px;
                    text-align: center;
                    text-decoration: underline;
                    cursor: pointer;
                }

                label {
                    display: block;
                    font-size: 10px;
                    margin-bottom: 3px;
                    color: var(--ui-color-grey-61);
                }
            }
        }
    }
}
